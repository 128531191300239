import { Domain } from '@/common/types'

const DOMAINS_AS_LOCALES = [
  // Domain.COM,
  Domain.SK,
  Domain.CZ,
  Domain.HU,
  Domain.RO,
  Domain.DE,
  Domain.HR,
  Domain.BG,
  Domain.PL,
  Domain.SI,
  Domain.BA,
  Domain.RS,
  Domain.GR,
  Domain.IT,
  Domain.UA,
  Domain.RU,
]

export function convertAbsoluteUrlToRelative(absoluteUrl: URL): string {
  return `${absoluteUrl.pathname}${absoluteUrl.search}${absoluteUrl.hash}`
}

/**
 * Removes all possible locales from pathname
 */
export function getPathnameWithoutLocale(pathname: string): string {
  if (!pathname) {
    return '/'
  }

  let newPathname = pathname

  for (let locale of DOMAINS_AS_LOCALES) {
    if (newPathname.startsWith(`/${locale}/`)) {
      newPathname = newPathname.replace(`/${locale}/`, '/')
    }
  }

  for (let locale of DOMAINS_AS_LOCALES) {
    if (newPathname == `/${locale}`) {
      newPathname = newPathname.replace(`/${locale}`, '/')
    }
  }

  if (newPathname === pathname) {
    return pathname
  }

  return getPathnameWithoutLocale(newPathname)
}

/**
 * Generate URL based on the provided base URL, href, and locale (domain also used as locale)
 *
 * - removes the locale from the href as it's a part of the baseUrl
 * - removes trailing slashes
 * - returns the final URL with search and hash values
 */
export function getUrl(baseUrl: string, href?: string): string {
  if (!href) {
    return baseUrl
  }

  const { pathname, search, hash } = new URL(href, baseUrl)

  let url = `${baseUrl}${getPathnameWithoutLocale(pathname)}`

  // Remove trailing slash
  if (url.endsWith('/')) {
    url = url.slice(0, -1)
  }

  // Add to final the URL search and hash values
  return `${url}${search}${hash}`
}

export function replaceFirstUaRuLocaleInUrl(url: string): string {
  // Regex pattern to match /ua/something or /ru/something immediately after the URL host
  const pattern = /^(https?:\/\/[^\/]+)\/(ua|ru)(\/[^\/]*)/

  // Replace the matched pattern with the base URL and the rest of the path without /ua or /ru
  const result = url.replace(pattern, '$1$3')

  return result
}

export function containsUaInPathname(url: string): boolean {
  if (isAbsoluteUrl(url)) {
    const pattern = /^(https?:\/\/[^\/]+\/)?(ua)(\/[^\/]*)/
    return (
      pattern.test(url) ||
      url.endsWith('/ua') ||
      url.endsWith('/uk') ||
      url.includes('ua.') ||
      url.includes('.ua')
    )
  } else {
    const pattern = /^(\/)?(ua|uk)(\/|$)/
    return pattern.test(url) || url.endsWith('/ua') || url.endsWith('/uk')
  }
}

export function containsRuInPathname(url: string): boolean {
  if (isAbsoluteUrl(url)) {
    const pattern = /^(https?:\/\/[^\/]+\/)?(ru)(\/[^\/]*)/
    return pattern.test(url) || url.endsWith('/ru')
  } else {
    const pattern = /^(\/)?(ru)(\/|$)/
    return pattern.test(url) || url.endsWith('/ru')
  }
}

export function isAbsoluteUrl(url: string): boolean {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

export function combineUrls(href: string, baseUrl?: string): string {
  if (baseUrl === undefined) {
    return href
  }

  if (isAbsoluteUrl(href) && !isAbsoluteUrl(baseUrl)) {
    return href
  }

  if (isAbsoluteUrl(href) && isAbsoluteUrl(baseUrl)) {
    const diff = new URL(href)
    const mergedUrl = new URL(diff.pathname, baseUrl)
    return mergedUrl.toString()
  }

  if (isAbsoluteUrl(baseUrl) && href !== '/') {
    const mergedUrl = new URL(href, baseUrl)
    return mergedUrl.toString()
  }

  if (isAbsoluteUrl(baseUrl) && href === '/') {
    const base = new URL(baseUrl)
    const isUa = containsUaInPathname(baseUrl)
    const isRu = containsRuInPathname(baseUrl)

    if (isUa) {
      base.pathname = `ua`
      return base.toString()
    }

    if (isRu) {
      base.pathname = `ru`
      return base.toString()
    }

    base.pathname = href
    return base.toString()
  }

  return href.startsWith('/') ? href : `/${href}`
}

export const getOnlyBaseUrl = (url: string): string => {
  if (isAbsoluteUrl(url)) {
    const urlObject = new URL(url)

    if (urlObject.pathname === '/') {
      return urlObject.toString()
    }

    return `${urlObject.origin}/`
  }

  return url
}

export const addLocaleToUrl = (url: string, locale: string): string => {
  if (isAbsoluteUrl(url)) {
    const urlObject = new URL(url)
    urlObject.pathname = `/${locale}${urlObject.pathname}`
    return urlObject.toString()
  } else {
    return url.startsWith('/') ? `/${locale}${url}` : `/${locale}/${url}`
  }
}

export function normalizeUaPath(url: string): string {
  const pattern = /(\/ua|\/ru|\/uk)+\//g
  return url.replace(pattern, '/ua/')
}

export function normalizeRuPath(url: string): string {
  const pattern = /(\/ru|\/ua|\/uk)+\//g
  return url.replace(pattern, '/ru/')
}

export function formatUrl({
  baseUrl,
  href,
  isUa,
  isRu,
  isLocalEnvironment,
  keepUrl = false,
}: {
  baseUrl?: string
  href: string
  isUa: boolean
  isRu: boolean
  isLocalEnvironment: boolean
  keepUrl?: boolean
}): string {
  const combinedHref = combineUrls(href.toString(), baseUrl)

  const formattedHref = (() => {
    const handleUaRuLocale = (url: string) => {
      const containsUaOrRuInPathname =
        containsRuInPathname(url) || containsUaInPathname(url)

      if (isUa && !containsUaOrRuInPathname) {
        return normalizeUaPath(addLocaleToUrl(url, 'ua'))
      }

      if (isRu && !containsUaOrRuInPathname) {
        return normalizeRuPath(addLocaleToUrl(url, 'ru'))
      }

      if (!isUa && !isRu) {
        return removeUaRuLocalesFromUrl(url)
      }

      return normalizeUaPath(url)
    }

    if (isLocalEnvironment && !keepUrl) {
      const relativeUrl = isAbsoluteUrl(combinedHref)
        ? convertAbsoluteUrlToRelative(new URL(combinedHref))
        : combinedHref

      return handleUaRuLocale(relativeUrl)
    }

    return handleUaRuLocale(combinedHref)
  })()

  return formattedHref
}

/**
 * Removes `/ua/` and `/ru/` when they appear as part of the URL path.
 * Such edge cases as product names started with `ru` or `ua` are handled =>
 * `/runcollg-mag-gymbeam` will be resolved without changes.
 *
 */
export const removeUaRuLocalesFromUrl = (url: string) =>
  url.replace(/\/(ua|ru)(\/|$)/g, '/')

/**
 * new URL() with fallback
 */
export const getURLObjectSafely = (url: string): URL => {
  try {
    return new URL(url)
  } catch {
    return {
      hash: '',
      host: '',
      hostname: '',
      href: '',
      origin: '',
      password: '',
      pathname: '',
      port: '',
      protocol: '',
      search: '',
      searchParams: new URLSearchParams(''),
      username: '',
      toString: () => '',
      toJSON: () => '',
    }
  }
}

export const handleMultipleSlashInPath = (path: string) =>
  path.replace(/\/{2,}/g, '/')
