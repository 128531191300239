import { Domain, StoreCodeType } from '@/common/types'
import { getURLObjectSafely } from './url-utils'

const BASIC_DOMAINS = [
  Domain.COM,
  Domain.SK,
  Domain.CZ,
  Domain.HU,
  Domain.RO,
  Domain.DE,
  Domain.HR,
  Domain.BG,
  Domain.PL,
  Domain.SI,
  Domain.BA,
  Domain.RS,
  Domain.GR,
  Domain.IT,
  Domain.UA,
]

export const STORE_CODES: {
  [key in Domain]: StoreCodeType
} = {
  [Domain.COM]: 'gymbeamcom',
  [Domain.SK]: 'gymbeamsk',
  [Domain.CZ]: 'gymbeamcz',
  [Domain.HU]: 'gymbeamhu',
  [Domain.RO]: 'gymbeamro',
  [Domain.DE]: 'gymbeamde',
  [Domain.HR]: 'gymbeamhr',
  [Domain.BG]: 'gymbeambg',
  [Domain.PL]: 'gymbeampl',
  [Domain.SI]: 'gymbeamsi',
  [Domain.BA]: 'gymbeamba',
  [Domain.RS]: 'gymbeamrs',
  [Domain.GR]: 'gymbeamgr',
  [Domain.IT]: 'gymbeamit',
  [Domain.UA]: 'ua',
  [Domain.RU]: 'ru',
}

export const FALLBACK_STORE_CODE: StoreCodeType = STORE_CODES[Domain.COM]

export const isLocalOrFeature = (origin?: string) =>
  origin?.includes('localhost:3000') ||
  (origin?.startsWith('https://gymbeam.') &&
    origin?.endsWith('.frontend.gymbeam.dev'))

export function getOriginAndLocale(url: string): {
  origin?: string
  locale?: Domain.UA | Domain.RU
} {
  const { origin, pathname } = getURLObjectSafely(url)
  const firstSegment = pathname?.split('/')[1]

  // Exception for UA - the first parameter is the selected locale
  if (firstSegment === Domain.UA || firstSegment === Domain.RU) {
    return { origin, locale: firstSegment }
  }

  return {
    origin,
  }
}

function getStoreCodeFromDomain(
  domain: Domain,
  locale?: Domain.UA | Domain.RU,
): StoreCodeType {
  // Exception for UA - the store code can be different based on locale
  if (locale && domain === Domain.UA) {
    return STORE_CODES[locale]
  }

  return STORE_CODES[domain] ?? FALLBACK_STORE_CODE
}

/**
 * Retrieves the store code based on the provided URL.
 *
 * - extract the origin and locale from the URL
 * - check the origin against different domain configurations (prod, next, staging24, qa24)
 * and returns the corresponding store code.
 * - fallback to {FALLBACK_STORE_CODE}
 * - handled exception for UA locales - the store code can be different based on locale
 *
 * @param url {string} The URL from which to extract the store code.
 * @returns {StoreCodeType} The store code based on the provided URL.
 */
export function getStoreCode(url: string): StoreCodeType {
  const { origin, locale } = getOriginAndLocale(url)

  if (!origin) return FALLBACK_STORE_CODE

  // prod
  for (const domain of BASIC_DOMAINS) {
    if (origin === `https://gymbeam.${domain}`) {
      return getStoreCodeFromDomain(domain, locale)
    }
  }

  // staging24
  for (const domain of BASIC_DOMAINS) {
    if (origin === `https://${domain}.staging24.gymbeam.dev`) {
      return getStoreCodeFromDomain(domain, locale)
    }
  }

  // next
  for (const domain of BASIC_DOMAINS) {
    if (origin === `https://${domain}.next.gymbeam.dev`) {
      return getStoreCodeFromDomain(domain, locale)
    }
  }

  // on local and branch envs
  return FALLBACK_STORE_CODE
}

export function getStoreCodeForMiddleware(
  url: string,
  storeCodeCookie?: string,
): StoreCodeType {
  const { origin } = getOriginAndLocale(url)

  if (!origin && !storeCodeCookie) {
    return FALLBACK_STORE_CODE
  }

  if (!origin && storeCodeCookie) {
    return storeCodeCookie as StoreCodeType
  }

  if (isLocalOrFeature(origin) && storeCodeCookie) {
    return storeCodeCookie as StoreCodeType
  }

  return getStoreCode(url)
}
